#drawer-adicionar-voucher .info {
    padding: 1em;
    border-radius: 4px;
    background-color: rgba(45,95,139, .16) !important;
    border: 1px solid rgba(45,95,139);
    border-left: 4px solid rgba(45,95,139);
    font-size: .9em;
}

#drawer-adicionar-voucher .info + .info {
    margin-top: 1em;
}

#drawer-adicionar-voucher .form-parceria {
    margin-left: .7em;
    padding-left: 1em;
    border-left: 1px solid rgba(45,95,139);
}