.menupaginacao-wrapper {
  margin-top: 1em;
  background-color: rgba(255, 255, 255, .86);
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  border-radius: 30px;
  padding: 0 .5em;
  width: fit-content;
  display: flex;
  align-items: center;
}

.menupaginacao-wrapper .menupaginacao-item {
  display: block;
  padding: 1em;
  text-decoration: none;
  color: #3d3d3d;
  transition: 250ms all;
}

.menupaginacao-wrapper .menupaginacao-item.active {
  color: #58359D;
  font-weight: bold;
  background-color: #eee;
}

.menupaginacao-wrapper .menupaginacao-anterior > * {
  transform: translate(0, 10%);
  color: #3d3d3d;
}

.menupaginacao-wrapper .menupaginacao-proximo > * {
  transform: translate(0, 10%);
  color: #3d3d3d;
}

.menupaginacao-wrapper > .menupaginacao-item:hover {
  color: #58359D;
  box-shadow: 0px 0px 20px rgba(20, 20, 20, .26);
}