.breadcrumbs-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0em;
}

.breadcrumbs-wrapper .breadcrumbs-item {
  padding: .5em 0;
  margin-right: .3em;
  color: rgba(40, 40, 40, .56);
  font-weight: 100;
  white-space: nowrap;
}

.breadcrumbs-wrapper a.breadcrumbs-item {
  text-decoration: none;
  color: #58359D;
}

.breadcrumbs-wrapper .breadcrumbs-item.active {
  color: rgba(40, 40, 40, 1);
  font-weight: 400;
  border-bottom: 1px solid rgba(40, 40, 40, .16);
}

.breadcrumbs-divider {
  margin-right: .3em;
  color: rgba(40, 40, 40, .46);
  font-weight: 100;
  padding: .5em;
}

.breadcrumbs-divider > * {
  position: relative;
  top: 3px;
}