input.campo-pesquisa {
  background-color: rgba(240, 240, 240, .56);
  border: 1px solid rgba(40, 40, 40, .16);
  padding: 1em 1em 1em 3em;
  border-radius: 30px;
  outline: none;
  margin-bottom: 1em;
  margin-right: 1em;
  transition: width 250ms, box-shadow 250ms 250ms, background-color 250ms 250ms;
  
  width: 170px;
  color: rgba(40, 40, 40, .66);
}

.collapsing {
  border-collapse: collapse !important;
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  .header-grid-acoes {
    display: flex;
    justify-content: flex-end;
  }
  input.campo-pesquisa:focus {
    width: 260px !important;
    border-color: rgba(20, 20, 20, .16);
    background-color: rgba(255, 255, 255, .86);
    box-shadow: 0px 0px 10px rgba(20, 20, 20, .26);
    transition: width 250ms 250ms, box-shadow 250ms, background-color 250ms;
  }
}
@media screen and (max-width: 767px) {
  .header-grid-acoes {
    display: flex;
    justify-content: space-between;
  }
  input.campo-pesquisa:focus {
    flex-grow: 1;
    border-color: rgba(20, 20, 20, .16);
    background-color: rgba(255, 255, 255, .86);
    box-shadow: 0px 0px 10px rgba(20, 20, 20, .26);
  }
}