.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right top, #58359d, #6a35a2, #7b35a5, #8b34a9, #9c32ab);
  /* background-image: linear-gradient(to left top, #364150, #3e4958, #465160, #4e5968, #566170); */
  overflow: auto;
}

.login-wrapper img {
  max-width: 150px;
}

.login-wrapper > * {
  margin-top: 2em;
  max-width: 450px;
  width: 90%;
}