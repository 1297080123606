.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right top, #58359d, #6a35a2, #7b35a5, #8b34a9, #9c32ab);
  /* background-image: linear-gradient(to left top, #364150, #3e4958, #465160, #4e5968, #566170); */
  overflow: auto;
}

.login-wrapper img {
  max-width: 150px;
}

.login-wrapper > * {
  margin-top: 2em;
  max-width: 450px;
  width: 90%;
}
.menupaginacao-wrapper {
  margin-top: 1em;
  background-color: rgba(255, 255, 255, .86);
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  border-radius: 30px;
  padding: 0 .5em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}

.menupaginacao-wrapper .menupaginacao-item {
  display: block;
  padding: 1em;
  text-decoration: none;
  color: #3d3d3d;
  transition: 250ms all;
}

.menupaginacao-wrapper .menupaginacao-item.active {
  color: #58359D;
  font-weight: bold;
  background-color: #eee;
}

.menupaginacao-wrapper .menupaginacao-anterior > * {
  transform: translate(0, 10%);
  color: #3d3d3d;
}

.menupaginacao-wrapper .menupaginacao-proximo > * {
  transform: translate(0, 10%);
  color: #3d3d3d;
}

.menupaginacao-wrapper > .menupaginacao-item:hover {
  color: #58359D;
  box-shadow: 0px 0px 20px rgba(20, 20, 20, .26);
}
.wrapper-pesquisa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrapper-pesquisa .label-pesquisado {
  margin-top: .5em;
  margin-bottom: 1em;
  margin-right: 1em;
}

.form-pesquisa {
  position: relative;
}

.form-pesquisa .icone-pesquisa {
  position: absolute;
  color: rgba(40, 40, 40, .46);
  top: .6em;
  left: .6em;
}

.form-pesquisa .progress-pesquisa {
  position: absolute;
  right: 1.5em;
  top: .8em;
}
.ql-editor {
    height: 300px !important;
}
#drawer-adicionar-voucher .info {
    padding: 1em;
    border-radius: 4px;
    background-color: rgba(45,95,139, .16) !important;
    border: 1px solid rgba(45,95,139);
    border-left: 4px solid rgba(45,95,139);
    font-size: .9em;
}

#drawer-adicionar-voucher .info + .info {
    margin-top: 1em;
}

#drawer-adicionar-voucher .form-parceria {
    margin-left: .7em;
    padding-left: 1em;
    border-left: 1px solid rgba(45,95,139);
}

.breadcrumbs-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0em;
}

.breadcrumbs-wrapper .breadcrumbs-item {
  padding: .5em 0;
  margin-right: .3em;
  color: rgba(40, 40, 40, .56);
  font-weight: 100;
  white-space: nowrap;
}

.breadcrumbs-wrapper a.breadcrumbs-item {
  text-decoration: none;
  color: #58359D;
}

.breadcrumbs-wrapper .breadcrumbs-item.active {
  color: rgba(40, 40, 40, 1);
  font-weight: 400;
  border-bottom: 1px solid rgba(40, 40, 40, .16);
}

.breadcrumbs-divider {
  margin-right: .3em;
  color: rgba(40, 40, 40, .46);
  font-weight: 100;
  padding: .5em;
}

.breadcrumbs-divider > * {
  position: relative;
  top: 3px;
}
input.campo-pesquisa {
  background-color: rgba(240, 240, 240, .56);
  border: 1px solid rgba(40, 40, 40, .16);
  padding: 1em 1em 1em 3em;
  border-radius: 30px;
  outline: none;
  margin-bottom: 1em;
  margin-right: 1em;
  transition: width 250ms, box-shadow 250ms 250ms, background-color 250ms 250ms;
  
  width: 170px;
  color: rgba(40, 40, 40, .66);
}

.collapsing {
  border-collapse: collapse !important;
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  .header-grid-acoes {
    display: flex;
    justify-content: flex-end;
  }
  input.campo-pesquisa:focus {
    width: 260px !important;
    border-color: rgba(20, 20, 20, .16);
    background-color: rgba(255, 255, 255, .86);
    box-shadow: 0px 0px 10px rgba(20, 20, 20, .26);
    transition: width 250ms 250ms, box-shadow 250ms, background-color 250ms;
  }
}
@media screen and (max-width: 767px) {
  .header-grid-acoes {
    display: flex;
    justify-content: space-between;
  }
  input.campo-pesquisa:focus {
    flex-grow: 1;
    border-color: rgba(20, 20, 20, .16);
    background-color: rgba(255, 255, 255, .86);
    box-shadow: 0px 0px 10px rgba(20, 20, 20, .26);
  }
}
.app-wrapper {
  margin-top: 5em;
  margin-bottom: 1em;
  padding-left: 261px;
  padding-right: 1.5em;
  margin-right: 1em;
  width: 100%;
  transition: padding 300ms;
}
.app-wrapper.fullscreen {
  padding-left: 1em;
}

@media screen and (max-width: 768px) {
  .app-wrapper {
    padding-left: 1em;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.andes-erro {
  padding: 1em;
  background-color: rgba(250, 90, 90, .86);
  border: 1px solid rgba(250, 90, 90, 1);
  color: white;
}

.andes-link {
  color: #58359D;
  text-decoration: none;
}

.ql-editor{
  min-height: 120px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

/* TEXTO */

.text-bold {
  font-weight: 700;
}

.text-primary {
  color: var(--cor-primaria);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  text-decoration: underline;
}

/* MARGIN */

.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: 8px !important;
}
.mt-1 {
  margin-top: 16px !important;
}
.mt-2 {
  margin-top: 32px !important;
}
.mt-3 {
  margin-top: 64px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: 8px !important;
}
.mb-1 {
  margin-bottom: 16px !important;
}
.mb-2 {
  margin-bottom: 32px !important;
}
.mb-3 {
  margin-bottom: 64px !important;
}

/* PADDING */

.pt-0 {
  padding-top: 0 !important;
}
.pt-05 {
  padding-top: 8px !important;
}
.pt-1 {
  padding-top: 16px !important;
}
.pt-2 {
  padding-top: 32px !important;
}
.pt-3 {
  padding-top: 64px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-05 {
  padding-bottom: 8px !important;
}
.pb-1 {
  padding-bottom: 16px !important;
}
.pb-2 {
  padding-bottom: 32px !important;
}
.pb-3 {
  padding-bottom: 64px !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ph-05 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.ph-1 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.ph-2 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.ph-3 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv-05 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv-1 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pv-2 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pv-3 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.bt-1 {
  border-top: 1px solid rgba(0, 0, 0, .15);
}

.bt-2 {
  border-top: 1px solid rgba(0, 0, 0, .25);
}

.bt-3 {
  border-top: 1px solid rgba(165, 88, 88, 0.35);
}

.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.bb-2 {
  border-bottom: 1px solid rgba(0, 0, 0, .25);
}

.bb-3 {
  border-bottom: 1px solid rgba(165, 88, 88, 0.35);
}

/* FLEX */

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.middle-align {
  align-items: center;
}

.flex.column.middle-align {
  justify-content: center;
}

.flex.center-align {
  justify-content: center;
}

.flex.column.center-align {
  align-items: center;
}

.flex.right-align {
  justify-content: flex-end;
}

.flex.column.right-align {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* CARD ESTATISTICA */

.card-estatistica .div-topo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: all 150ms;
}

.card-estatistica:hover .div-topo {
  height: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .15);
}

.card-estatistica .div-topo.primary {
  background-color: #58359d;
}

.card-estatistica .div-topo.secondary {
  background-color: #ff3f6e;
}

.card-estatistica .div-topo.tertiary {
  background-color: #ff6033;
}

.card-estatistica .icone {
  font-size: 56px;
  color: rgba(10, 10, 10, .3);
  position: absolute;
  right: 14px;
  top: .5em;
  transition: all 150ms;
  opacity: .4;
}

.card-estatistica:hover .icone {
  /* color: rgba(10, 10, 10, .5); */
  opacity: .75;
}

.card-estatistica .icone.primary {
  color: #58359d;
}

.card-estatistica .icone.secondary {
  color: #ff3f6e;
}

.card-estatistica .icone.tertiary {
  color: #ff6033;
}

/* PREVIEW ESTABELECIMENTO */

.preview-estabelecimento {
  position: relative;
}

.preview-estabelecimento .preview-capa-estabelecimento {
  max-width: 100%;
}

.preview-estabelecimento .preview-perfil-estabelecimento {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  overflow: hidden;
  height: 64px;
  width: 64px;
  border: 1px solid rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-estabelecimento .preview-perfil-estabelecimento img {
  height: 100%;
  width: auto;
}
/* DROPZONE */

.section-dropzone {
  border: 1px dashed rgba(0, 0, 0, .25);
  border-radius: 3px;
  padding: 0 1em;
  text-align: center;
}
