.app-wrapper {
  margin-top: 5em;
  margin-bottom: 1em;
  padding-left: 261px;
  padding-right: 1.5em;
  margin-right: 1em;
  width: 100%;
  transition: padding 300ms;
}
.app-wrapper.fullscreen {
  padding-left: 1em;
}

@media screen and (max-width: 768px) {
  .app-wrapper {
    padding-left: 1em;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.andes-erro {
  padding: 1em;
  background-color: rgba(250, 90, 90, .86);
  border: 1px solid rgba(250, 90, 90, 1);
  color: white;
}

.andes-link {
  color: #58359D;
  text-decoration: none;
}

.ql-editor{
  min-height: 120px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

/* TEXTO */

.text-bold {
  font-weight: 700;
}

.text-primary {
  color: var(--cor-primaria);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  text-decoration: underline;
}

/* MARGIN */

.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: 8px !important;
}
.mt-1 {
  margin-top: 16px !important;
}
.mt-2 {
  margin-top: 32px !important;
}
.mt-3 {
  margin-top: 64px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: 8px !important;
}
.mb-1 {
  margin-bottom: 16px !important;
}
.mb-2 {
  margin-bottom: 32px !important;
}
.mb-3 {
  margin-bottom: 64px !important;
}

/* PADDING */

.pt-0 {
  padding-top: 0 !important;
}
.pt-05 {
  padding-top: 8px !important;
}
.pt-1 {
  padding-top: 16px !important;
}
.pt-2 {
  padding-top: 32px !important;
}
.pt-3 {
  padding-top: 64px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-05 {
  padding-bottom: 8px !important;
}
.pb-1 {
  padding-bottom: 16px !important;
}
.pb-2 {
  padding-bottom: 32px !important;
}
.pb-3 {
  padding-bottom: 64px !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ph-05 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.ph-1 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.ph-2 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.ph-3 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv-05 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv-1 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pv-2 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pv-3 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.bt-1 {
  border-top: 1px solid rgba(0, 0, 0, .15);
}

.bt-2 {
  border-top: 1px solid rgba(0, 0, 0, .25);
}

.bt-3 {
  border-top: 1px solid rgba(165, 88, 88, 0.35);
}

.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.bb-2 {
  border-bottom: 1px solid rgba(0, 0, 0, .25);
}

.bb-3 {
  border-bottom: 1px solid rgba(165, 88, 88, 0.35);
}

/* FLEX */

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.middle-align {
  align-items: center;
}

.flex.column.middle-align {
  justify-content: center;
}

.flex.center-align {
  justify-content: center;
}

.flex.column.center-align {
  align-items: center;
}

.flex.right-align {
  justify-content: flex-end;
}

.flex.column.right-align {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* CARD ESTATISTICA */

.card-estatistica .div-topo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: all 150ms;
}

.card-estatistica:hover .div-topo {
  height: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .15);
}

.card-estatistica .div-topo.primary {
  background-color: #58359d;
}

.card-estatistica .div-topo.secondary {
  background-color: #ff3f6e;
}

.card-estatistica .div-topo.tertiary {
  background-color: #ff6033;
}

.card-estatistica .icone {
  font-size: 56px;
  color: rgba(10, 10, 10, .3);
  position: absolute;
  right: 14px;
  top: .5em;
  transition: all 150ms;
  opacity: .4;
}

.card-estatistica:hover .icone {
  /* color: rgba(10, 10, 10, .5); */
  opacity: .75;
}

.card-estatistica .icone.primary {
  color: #58359d;
}

.card-estatistica .icone.secondary {
  color: #ff3f6e;
}

.card-estatistica .icone.tertiary {
  color: #ff6033;
}

/* PREVIEW ESTABELECIMENTO */

.preview-estabelecimento {
  position: relative;
}

.preview-estabelecimento .preview-capa-estabelecimento {
  max-width: 100%;
}

.preview-estabelecimento .preview-perfil-estabelecimento {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  overflow: hidden;
  height: 64px;
  width: 64px;
  border: 1px solid rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-estabelecimento .preview-perfil-estabelecimento img {
  height: 100%;
  width: auto;
}
/* DROPZONE */

.section-dropzone {
  border: 1px dashed rgba(0, 0, 0, .25);
  border-radius: 3px;
  padding: 0 1em;
  text-align: center;
}