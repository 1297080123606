.wrapper-pesquisa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrapper-pesquisa .label-pesquisado {
  margin-top: .5em;
  margin-bottom: 1em;
  margin-right: 1em;
}

.form-pesquisa {
  position: relative;
}

.form-pesquisa .icone-pesquisa {
  position: absolute;
  color: rgba(40, 40, 40, .46);
  top: .6em;
  left: .6em;
}

.form-pesquisa .progress-pesquisa {
  position: absolute;
  right: 1.5em;
  top: .8em;
}